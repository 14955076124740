import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';

@Injectable()
export class SocketService {
    constructor(private socket: Socket) { }

    sendMessage(userId: string) {
        const payload = { userId };
        this.socket.emit('send_file', payload);
    }

    getMessage() {
        return this.socket.fromEvent('progress').pipe(map((data: any) => data));
    }

    sendCrossClarification(userId: string) {
        const payload = { userId };
        this.socket.emit('generate_cross_clarification', payload);
    }
    getMessageCrossClarification() {
        return this.socket.fromEvent('progress_cross_clarification').pipe(map((data: any) => data));
    }
}